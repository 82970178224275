import { onCallConstants } from "../constants";

const initialState =  { loading: null, siteUserStatus: null,siteUserPosition: null, siteUserAcademicYears: null,siteUsers:null } || {};

export function onCall(state = initialState, action) {
    switch (action.type) {
    case onCallConstants.ONCALL_REQUEST:
        return {
            loading: true
        };
    case onCallConstants.ONCALL_SUCCESS:
        return {
            loading: false,
            siteUserStatus:action.records.data['status'],
            siteUserPosition:action.records.data['position'],
            siteUserAcademicYears:action.records.data['student'],
        };
    case onCallConstants.ONCALL_FAILURE:
        return { 
            error: action.error
        };

    case onCallConstants.ONCALL_POSITION_REQUEST:
        return {
            siteUserStatus:state.siteUserStatus,
            siteUserAcademicYears:state.siteUserAcademicYears,
            loading: true
        };
    case onCallConstants.ONCALL_POSITION_SUCCESS:
        return {
            loading: false,
            siteUserStatus:state.siteUserStatus,
            siteUserAcademicYears:state.siteUserAcademicYears,
            siteUserPosition:action.records.data
        };
    case onCallConstants.ONCALL_POSITION_FAILURE:
        return { 
            error: action.error
        };

    case onCallConstants.ONCALL_ACADEMIC_YEARS_REQUEST:
        return {
            siteUserStatus:state.siteUserStatus,
            siteUserPosition:state.siteUserPosition,
            loading: true
        };
    case onCallConstants.ONCALL_ACADEMIC_YEARS_SUCCESS:
        return {
            loading: false,
            siteUserStatus:state.siteUserStatus,
            siteUserPosition:state.siteUserPosition,
            siteUserAcademicYears:action.records.data
        };
    case onCallConstants.ONCALL_ACADEMIC_YEARS_FAILURE:
        return { 
            error: action.error
        };
    case onCallConstants.ONCALL_SITEUSERS_REQUEST:
        return {
            siteUserStatus:state.siteUserStatus,
            siteUserPosition:state.siteUserPosition,
            siteUserAcademicYears:state.siteUserAcademicYears,
            loading: true
        };
    case onCallConstants.ONCALL_SITEUSERS_SUCCESS:
        return {
            loading: false,
            siteUserStatus:state.siteUserStatus,
            siteUserPosition:state.siteUserPosition,
            siteUserAcademicYears:state.siteUserAcademicYears,
            siteUsers:action.records.data
        };
    case onCallConstants.ONCALL_SITEUSERS_FAILURE:
        return { 
            error: action.error
        };

    

    case onCallConstants.ONCALL_LSDI_SEARCH_REQUEST:
        return {
            siteUserStatus:state.siteUserStatus,
            siteUserPosition:state.siteUserPosition,
            siteUserAcademicYears:state.siteUserAcademicYears,
            siteUsers:state.siteUsers,
            loading: true
        };
    case onCallConstants.ONCALL_LSDI_SEARCH_SUCCESS:
        return {
            loading: false,
            siteUserStatus:state.siteUserStatus,
            siteUserPosition:state.siteUserPosition,
            siteUserAcademicYears:state.siteUserAcademicYears,
            siteUsers:state.siteUsers,
            searchResultsAll:action.records.data,
        };
    case onCallConstants.ONCALL_LSDI_SEARCH_FAILURE:
        return { 
            error: action.error
        };

    
    
    default:
        return state;
    }
}