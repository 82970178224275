import { combineReducers } from "redux";
import { users } from "./user.reducer";
import { onCall } from "./oncall.reducer";
import { authentication } from "./authentication.reducer";




const rootReducer = combineReducers({
    authentication,
    onCall,
    users
});

export default rootReducer;